import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable } from 'rxjs-compat';
import { catchError, map } from 'rxjs/operators';
import { IntegrationObject, OAuthStatusObject, ZohoEmailAuthorizationObject } from '../models/integration.model';
import { Dictionary } from '@amcharts/amcharts4/core';
import { VpnServerInfoObject } from '../models/vpnserver.model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  /**
   * Public variables
   */
  authorizeDocusign:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   /**
   * Private variables
   */
    private _apiUrl: string = environment.baseApiUrl;

  constructor(private _httpClient: HttpClient) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
   private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Functiont to get integration
   */
  public getIntegration(type: string): Observable<IntegrationObject> {
    return this._httpClient.get<IntegrationObject>(`${this._apiUrl}/integration/${type}`).pipe(
      map((data: IntegrationObject) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to check integration status
   */
  public checkIntegrationStatus(type: string, id: number): Observable<OAuthStatusObject> {
    return this._httpClient.get<OAuthStatusObject>(`${this._apiUrl}/integration/status/${type}/${id}`).pipe(
      map((data: OAuthStatusObject) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to reset integration
   */
  public resetIntegration(id): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/integration/${id}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to refresh server list
   */
  public refreshServerList(): Observable<string> {
    return this._httpClient.get<string>(`${this._apiUrl}/integration/vpn/refresh`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to check integrations
   */
  public checkIntegrations() : Observable<boolean> {
    return this._httpClient.get<boolean>(`${this._apiUrl}/integration/check/integrations`).pipe(
      map((data: boolean) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to reset integration
   */
  public deleteService(serviceId: number): Observable<boolean> {
    return this._httpClient.delete<boolean>(`${this._apiUrl}/integration/${serviceId}`).pipe(
      map((data: boolean) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Functon to save integration
   */
  public saveIntegration(model: IntegrationObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/integration`, model).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get vpn server groups
   */
  public getVpnServerGroups(): Observable<Dictionary<string, Array<number>>> {
    return this._httpClient.get<Dictionary<string, Array<number>>>(`${this._apiUrl}/integration/vpn/groups`).pipe(
      map((data: Dictionary<string, Array<number>>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get vpn server info
   */
  public getVpnServerInfo(id: number): Observable<VpnServerInfoObject> {
    return this._httpClient.get<VpnServerInfoObject>(`${this._apiUrl}/integration/vpn/${id}`).pipe(
      map((data: VpnServerInfoObject) => data),
      catchError(this._handleError)
    );
  }

    /**
   * Function to get zoho authorization
   */
    public getZohoMailAuthorization(
      redirectUrl: string,
      code: string,
      location: string,
      accountServer: string
    ): Observable<ZohoEmailAuthorizationObject> {
      const queryParams =
        `redirectUrl=${redirectUrl}&code=${code}&location=${location}&accountServer=${accountServer}`;

      console.log(queryParams);

      return this._httpClient
        .get<ZohoEmailAuthorizationObject>(
          `${this._apiUrl}/EmailVendor/zoho/authorize?${queryParams}`
        )
        .pipe(
          map((data: ZohoEmailAuthorizationObject) => data),
          catchError(this._handleError)
        );
    }
}

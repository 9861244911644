<nb-card class="card-dialog">
  <nb-card-header> Zoho Email Integration <nb-icon class="close-icon" icon="close" (click)="dialogRef.close()">
    </nb-icon></nb-card-header>
  <nb-card-body>

    <form class="w-100 m-t dialog-form" [formGroup]="zohoEmailIntegrationForm">

      <div class="field">
        <label>Zoho Email Link*</label>
        <input matInput formControlName="ZohoEmailLink" name="ZohoEmailLink" (click)="openLink(zohoEmailIntegrationForm.get('ZohoEmailLink').value)" class="clickable-input">
      </div>

      <div class="field">
        <label>Zoho Email Client*</label>
        <input matInput formControlName="ZohoEmailClient" name="ZohoEmailClient" (click)="openLink(zohoEmailIntegrationForm.get('ZohoEmailClient').value)" class="clickable-input">
      </div>

      <div class="field">
        <label>Home Page URL*
          <nb-icon icon="copy-outline" style="margin-left: 8px; cursor: pointer;"
            (click)="copyToClipboard(zohoEmailIntegrationForm.get('HomePageUrl').value)"></nb-icon>
        </label>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput formControlName="HomePageUrl" name="HomePageUrl">

        </mat-form-field>
      </div>

      <div class="field">
        <label>Redirect URL* <nb-icon icon="copy-outline" style="margin-left: 8px; cursor: pointer;"
            (click)="copyToClipboard(zohoEmailIntegrationForm.get('RedirectUrl').value)"></nb-icon></label>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput formControlName="RedirectUrl" name="RedirectUrl">

        </mat-form-field>
      </div>

      <div class="field">
        <label>Client Id*</label>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput formControlName="ClientId" name="ClientId" autocomplete="new-password">

        </mat-form-field>
      </div>

      <div class="field">
        <label>Client Secret*</label>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput formControlName="ClientSecret" name="ClientSecret" autocomplete="new-password">

        </mat-form-field>
      </div>

      <div class="field">
        <label>Email*</label>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput formControlName="EmailAddress" name="EmailAddress" type="email" autocomplete="new-password"
            (input)="emailInputChanged()">
        </mat-form-field>
        <p class="error_message"
          *ngIf="zohoEmailIntegrationForm.get('EmailAddress').invalid && zohoEmailIntegrationForm.get('EmailAddress').touched">
          Invalid email format</p>
      </div>

    </form>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton size="tiny" status="danger" (click)="dialogRef.close()">Cancel</button>
    <button class="ml-1" nbButton size="tiny" status="success"
      [disabled]="zohoEmailIntegrationForm.invalid || !isFormModified || isLoading" (click)="submit();"
      [nbSpinner]="isLoading">Submit</button>
  </nb-card-footer>
</nb-card>

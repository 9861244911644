import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme'
import { EmailValidationPipe } from '../../custom-pipes/email-validation.pipe'
import { Clipboard } from '@angular/cdk/clipboard'
import { ZohoEmailConfigurationObject, ZohoEmailObject } from 'app/@core/models/integration.model'
import { UserService } from 'app/@core/services/user.service'
import { take } from 'rxjs/operators'

@Component({
  selector: 'ngx-zoho-email-form',
  templateUrl: './zoho-email-form.component.html',
  styleUrls: ['./zoho-email-form.component.scss'],
})
export class ZohoEmailFormComponent implements OnInit {
  @Input() userId: number;
  zohoEmailIntegrationForm: FormGroup;
  originModel: ZohoEmailObject = new ZohoEmailObject();
  isFormModified: boolean = false;
  isLoading: boolean = false;

  constructor(
    public dialogRef: NbDialogRef<ZohoEmailFormComponent>,
    public _formBuilder: FormBuilder,
    public _toastrService: NbToastrService,
    public _dialogService: NbDialogService,
    public emailValidationPipe: EmailValidationPipe,
    private _clipboard: Clipboard,
    private _cdRef: ChangeDetectorRef,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this._initZohoEmailIntegrationForm();
    this._cdRef.detectChanges();
    this._subscribeToFormChanges();
    this.getZohoMailConfiguration();
  }


  copyToClipboard(value: string): void {
    value
      ? (this._clipboard.copy(value), this._toastrService.success('Copied to clipboard'))
      : this._toastrService.warning('No value to copy');
  }

  emailInputChanged() {
    let val = this.zohoEmailIntegrationForm.get('Email').value
    this.zohoEmailIntegrationForm.get('Email').setErrors(val && this.emailValidationPipe.transform()(new FormControl(val)) ? { invalidFormat: true } : null)
  }


  private _subscribeToFormChanges() {
    this.zohoEmailIntegrationForm.valueChanges.subscribe((value) => {
      this.isFormModified =
        value.ClientId === this.originModel.ClientId &&
        value.Email === this.originModel.EmailAddress &&
        value.ClientSecret === this.originModel.ClientSecret
          ? false
          : true;
    });
  }


  private _initZohoEmailIntegrationForm(): void {
    this.zohoEmailIntegrationForm = this._formBuilder.group({
      ZohoEmailLink: new FormControl(
        { value: 'https://accounts.zoho.com/signin', disabled: false },
        Validators.required
      ),
      ZohoEmailClient: new FormControl({ value: 'https://api-console.zoho.com', disabled: false }, Validators.required),
      HomePageUrl: new FormControl({ value: 'https://dev.hubrick.techup.me/', disabled: true }, Validators.required),
      RedirectUrl: new FormControl(
        { value: `https://dev.hubrick.techup.me/pages/my-profile/${this.userId}`, disabled: true },
        Validators.required
      ),
      ClientId: new FormControl('', Validators.required),
      ClientSecret: new FormControl('', Validators.required),
      EmailAddress: new FormControl('', [Validators.required, Validators.email]),
    })
  }

  openLink(url: string): void {
    url ? window.open(url, '_blank') : this._toastrService.warning('Invalid URL');
  }

  submit() {
    if (this.zohoEmailIntegrationForm.valid) {
      const formValue = this.zohoEmailIntegrationForm.value

      const request: ZohoEmailConfigurationObject = {
        ClientId: formValue.ClientId,
        ClientSecret: formValue.ClientSecret,
        EmailAddress: formValue.EmailAddress,
      }
      this.isLoading = true

      this._userService.createZohoMailConfiguration(request).pipe(take(1)).subscribe({
        next: (response: string) => {
          this.isLoading = false;
          this._toastrService.success(response);
          this.getZohoMailConfiguration();
        },
        error: (error) => {
          this._toastrService.danger(error.error.Content);
          this.isLoading = false;
        },
      })
    }
  }

  getZohoMailConfiguration() {
    this._userService.getZohoMailConfiguration().pipe(take(1)).subscribe({
      next: (response: ZohoEmailConfigurationObject) => {
        this.zohoEmailIntegrationForm.patchValue({
          ClientId: response.ClientId,
          ClientSecret: response.ClientSecret,
          EmailAddress: response.EmailAddress,
        })
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content)
      },
    })
  }

  close() {
    this.dialogRef.close()
  }
}

<nb-card>
  <nb-card-header>
    Configure Columns
    <nb-icon class="close-icon" icon="close" (click)="closeDialog()"> </nb-icon>
  </nb-card-header>
  <nb-card-body [formGroup]="form">
    <div class="field">
      <label>Name</label>
      <input type="text" nbInput fullWidth fieldSize="tiny" formControlName="name" />
      <button nbButton size="tiny" status="primary" (click)="addItems()" [disabled]="isLoading || !form.valid" [nbSpinner]="isLoading">Add</button>
    </div>
    <div class="items-container">
      <div class="item" *ngFor="let item of items">
        <p>{{ item.Title }}</p>
        <nb-icon
          class="delete-icon"
          icon="close-outline"
          status="danger"
          nbTooltip="Remove"
          nbTooltipStatus="danger"
          (click)="deleteItem(item)"
          *ngIf="item.IsCustomColumn"
        ></nb-icon>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel pull-right" nbButton size="tiny" status="danger" (click)="closeDialog()">Close</button>
  </nb-card-footer>
  <nb-spinner *ngIf="loading" class="global-spinner"></nb-spinner>
</nb-card>

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataColumnResponse } from './reporting-data.model';
import { ExportDataObject } from 'app/pages/lead/profile/loan-reminders/timeline.model';

@Injectable({
  providedIn: 'root'
})
export class ReportingDataService {

  private _apiUrl: string = environment.baseApiUrl;

  constructor(private _httpClient: HttpClient) { }

  /**
   * Function to get All Columns
   */
  public getAllColumns(): Observable<Array<DataColumnResponse>> {
    return this._httpClient.get<Array<DataColumnResponse>>(`${this._apiUrl}/ReportData/column`).pipe(
      map((data: Array<DataColumnResponse>) => data),
      catchError(this._handleError)
    );
  }

  /**
 * Function to delete column
 */
  public deleteColumn(allFields: string[]): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/reportdata/column/remove`, {body: allFields}).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

    /**
   * Function to add column
   */
  public addColumn(request: string[]): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/reportdata/column`, request).pipe(
      map((data: any) => data),
      catchError(this._handleError)
    );
  }

  /**
 * Function to get dropdown data
 */
  public getDropdownData(userId: number, customerId?: number): Observable<any> {
    let params = new HttpParams().set('userId', userId.toString());

    if (customerId !== null) {
      params = params.set('customerId', customerId.toString());
    }

    return this._httpClient.get<any>(`${this._apiUrl}/ReportData/dropdown`, { params }).pipe(
      map((data: any) => data),
      catchError(this._handleError)
    );
  }

  /**
 * Function to export data
 */
  public exportData(request: ExportDataObject): Observable<Blob> {
    return this._httpClient.post(`${this._apiUrl}/reportdata/export`, request, { responseType: 'blob' }).pipe(
      map((data: any) => data),
      catchError(this._parseErrorBlob)
    );
  }

  /**
   * Function to download file from blob storage
   */
  downloadFileFromBlobStorage(id: number): Observable<Blob> {
    return this._httpClient.get(`${this._apiUrl}/reportdata/table/${id}/download`, { responseType: 'blob'}).pipe(
      map((data: Blob) => data),
      catchError(this._parseErrorBlob));
  }


   /**
  * Function to handle error
  * @param error
  * @returns
  */
   private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`)
    }
    // return an observable with a user-facing error message
    return throwError(error)
  }

    /**
   * Function to parse error blob
   */
    private _parseErrorBlob(err: HttpErrorResponse): Observable<any> {
      const reader: FileReader = new FileReader();

      const obs = Observable.create((observer: any) => {
        reader.onloadend = (e) => {
          observer.error(JSON.parse(reader.result.toString()));
          observer.complete();
        }
      });
      reader.readAsText(err.error);
      return obs;
    }
}

<div class="spacing" [formGroup]="settingsGroup" [nbSpinner]="isLoading">
    <h5>Notifications settings</h5>
    <p class="subtitle">Where you receive these notifications</p>

    <nb-icon icon="arrow-back-outline" class="back-icon" (click)="backToNotifications()"></nb-icon>

    <div class="d-flex justify-content-between pt-10 pb-10">
        <div class="d-flex">
            <nb-icon class="setting-icon" icon="monitor-outline"></nb-icon>
            <p class="setting-title">Push</p>
        </div>
        <nb-toggle formControlName="push"></nb-toggle>
    </div>

    <div class="d-flex justify-content-between pt-10 pb-10">
        <div class="d-flex">
            <nb-icon class="setting-icon" icon="email-outline"></nb-icon>
            <p class="setting-title">Email</p>
        </div>
        <nb-toggle formControlName="email"></nb-toggle>
    </div>

    <div class="d-flex justify-content-between pt-10 pb-10">
        <div class="d-flex">
            <nb-icon class="setting-icon" icon="message-square-outline"></nb-icon>
            <p class="setting-title">SMS</p>
        </div>
        <nb-toggle formControlName="sms"></nb-toggle>
    </div>
</div>

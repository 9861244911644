import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core'

@Directive({
  selector: '[dragColumn]',
})
export class DragColumnDirective {
  constructor(private _elementRef: ElementRef) {}
  @Input() column: any
  @Input() allColumns: any
  @Input() viewPortWidth: any

  @Output() dragColumnStart = new EventEmitter()
  @Output() dragColumn = new EventEmitter()
  @Output() dragColumnEnd = new EventEmitter()

  COLUMN_MIN_WIDTH: number = 50
  dragStartPosition: number

  @HostListener('mousedown', ['$event'])
  public onDragStart(event) {
    this.dragStartPosition = event.x
    this.column.dragInProgress = true

    event.stopImmediatePropagation()

    this.dragColumnStart.emit()
  }

  @HostListener('document:mousemove', ['$event'])
  public onDrag(event) {
    if (this.column.dragInProgress) {
      let offset = event.x - this.dragStartPosition
      let newWidth = Math.max(this.COLUMN_MIN_WIDTH, this.column.originalWidth + offset)
      this.column.Width = newWidth

      let currentTotalWidth = this.getTotalColumnWidth()
      let isLastColumn = this.allColumns[this.allColumns.length - 1] == this.column
      // amend rest of the columns if grid wouldn't fill up full width
      if (currentTotalWidth < this.viewPortWidth && !isLastColumn) {
        let currentColumnIndex = this.allColumns.findIndex((x) => x == this.column)
        let neighbourColumn =
          currentColumnIndex == this.allColumns.length - 1
            ? this.allColumns[currentColumnIndex - 1]
            : this.allColumns[currentColumnIndex + 1]

        neighbourColumn.Width += this.viewPortWidth - currentTotalWidth
      }

      //this.dragColumn.emit()
    }
  }

  @HostListener('document:mouseup', ['$event'])
  public onDragEnd(event) {
    if (this.column.dragInProgress) {
      let currentTotalWidth = this.getTotalColumnWidth()
      let isLastColumn = this.allColumns[this.allColumns.length - 1] == this.column
      // Handle last column resized to a smaller viewport - distribute the difference between the other columns to keep the grid full-width
      if (currentTotalWidth < this.viewPortWidth && isLastColumn) {
        let differencePerColumn = ((this.viewPortWidth - currentTotalWidth) * 1.0) / (this.allColumns.length - 1)
        this.allColumns.slice(0, this.allColumns.length - 1).forEach(x=> x.Width += differencePerColumn)
      }
      this.allColumns.forEach((x) => (x.originalWidth = x.Width))
      this.column.dragInProgress = false
      this.dragColumnEnd.emit()
    }
  }

  private getTotalColumnWidth() {
    return this.allColumns.reduce((acc, cur) => {
      acc += cur.Width
      return acc
    }, 0)
  }
}

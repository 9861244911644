import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router'
import { map } from 'rxjs/operators'
import { SharedService } from './pages/shared/shared.service'
import { Observable, of } from 'rxjs'
import { ApplicationPageService } from './@core/services/application-page.service'
import { UserApplicationPageResponseObject } from './@core/models/application-page.model'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private _applicationPageService: ApplicationPageService,
              private _sharedService: SharedService,
              private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let applicationRoutesItem = localStorage.getItem('HubrickUserApplicationRoutes');
    if (applicationRoutesItem) {
      let applicationRoutes = JSON.parse(applicationRoutesItem);
      return this._handleRoute(applicationRoutes, state);
    }

    return this._applicationPageService.getUserApplicationPagesAndRoutes().pipe(
      map((data: UserApplicationPageResponseObject) => {
        // Allow My Profile access with or without route parameter
        let findApplicationMyProfileIndex = data.AllApplicationRoutes.findIndex(route => route.includes('my-profile'));
        if (findApplicationMyProfileIndex && data.AllApplicationRoutes[findApplicationMyProfileIndex] == '/pages/my-profile/:id') {
          data.AllApplicationRoutes[findApplicationMyProfileIndex] = '/pages/my-profile/' + localStorage.getItem('userHubId');
        };

        let findApplicationPagesMyProfileIndex = data.ApplicationPages.findIndex(route => route.Route.includes('my-profile'));
        if (findApplicationPagesMyProfileIndex && data.ApplicationPages[findApplicationPagesMyProfileIndex].Route == 'my-profile/:id') {
          data.ApplicationPages[findApplicationPagesMyProfileIndex].Route = 'my-profile/' + localStorage.getItem('userHubId');
          data.ApplicationPages[findApplicationPagesMyProfileIndex].FullRoute = '/pages/my-profile/' + localStorage.getItem('userHubId');
        }

        this._sharedService.applicationPagesSubject.next(data.ApplicationPages);
        this._sharedService.allApplicationRoutesSubject.next(data.AllApplicationRoutes);
        this._sharedService.isLoggedInUserAdmin.next(data.IsAdmin);

        localStorage.setItem('HubrickUserApplicationRoutes', JSON.stringify(data.AllApplicationRoutes));
        localStorage.setItem('HubrickUserApplicationPages', JSON.stringify(data.ApplicationPages));

        let applicationRoutes = data.AllApplicationRoutes;

        this._handleRoute(applicationRoutes, state);
      })
    )
  }

/**
   * Function to handle route
   */
private _handleRoute(applicationRoutes: Array<string>, state: RouterStateSnapshot): Observable<boolean> {
  let findMyProfileRoute = applicationRoutes.find(route => route.includes('my-profile'));
  if (findMyProfileRoute) {
    applicationRoutes.splice(applicationRoutes.indexOf(findMyProfileRoute), 1);
    applicationRoutes.push('/pages/my-profile/:id');
    applicationRoutes.push('/pages/my-profile');
  }

  if (!applicationRoutes) {
    this._router.navigate(['auth/login']);
    return of(false);
  }

  let currentFullRoute = state.url;

  if (currentFullRoute.includes('404')) {
    return of(true);
  }

  // Split the route and query params
  let [currentRoute] = currentFullRoute.split('?');
  // Check if the route (without query params) matches any application route
  let matchFound = applicationRoutes.some(route => {
    // Convert the application route to a regex pattern
    let regexPattern = this._routeToRegex(route);
    // Test the current route against the regex pattern
    return regexPattern.test(currentRoute);
  });

  if (matchFound) {
    return of(true);
  } else {
    this._router.navigate([applicationRoutes[0]]);
    return of(false);
  }
}

  /**
   * Function to convert a dynamic route into a regex pattern
   */
  private _routeToRegex(route: string): RegExp {
    const regexPattern = route.replace(':id', '\\d+');
    return new RegExp(`^${regexPattern}$`);
  }
}

export class IntegrationObject {
  constructor(
    public AccessToken: string | null = null,
    public RefreshToken: string | null = null,
    public AccountId: string | null = null,
    public AuthUri: string | null = null,
    public BaseApiUrl: string | null = null,
    public ClientId: string | null = null,
    public CreatedDate : string | null | Date = null,
    public Email: string | null = null,
    public Id: number | null = null,
    public Name: string | null = null,
    public Password: string | null = null,
    public RedirectUri: string | null = null,
    public SecretKey: string | null = null,
    public ServiceType: number | null = null,
    public TokenUri: string | null = null,
    public UserId: string | null = null,
    public isDelete: boolean | null = null,
  ) { }
}

export class OAuthStatusObject {
  constructor(
    public IsValid: boolean | null = null,
    public UserName: string | null = null,
    public AuthUri: string | null = null
  ){}
}

export class ZohoEmailConfigurationObject {
  public ClientId: string | null = null;
  public ClientSecret: string | null = null;
  public EmailAddress: string | null = null
}

export class ZohoEmailObject extends ZohoEmailConfigurationObject {
  public ZohoEmailLink: string | null = null;
  public ZohoEmailClient: string | null = null;
  public HomePageUrl: string | null = null;
  public RedirectUrl: string | null = null;
}

export class ZohoEmailStatusObject {
  constructor(
    public Status: number | null = null,
    public AuthorizationLink: string | null = null
  ){}
}

export class ZohoEmailAuthorizationObject {
  public RedirectUrl: string | null = null;
  public Code: string | null = null;
  public Location: string | null = null;
  public AccountServer: string | null = null;
}

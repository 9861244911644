import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { take } from 'rxjs/operators';
import { ProceedDialogComponent } from '../../proceed-dialog/proceed-dialog.component';
import { DataColumnResponse } from '../reporting-data.model';
import { ReportingDataService } from '../reporting-data.service';

@Component({
  selector: 'ngx-configure-column-form',
  templateUrl: './configure-column-form.component.html',
  styleUrls: ['./configure-column-form.component.scss']
})
export class ConfigureColumnFormComponent implements OnInit {

  form: FormGroup;
  items: Array<DataColumnResponse>;
  isLoading: boolean = false;
  loading: boolean = false;
  columns: Array<string>;
  hasChanges: boolean = false;

  constructor(
    public dialogRef: NbDialogRef<ConfigureColumnFormComponent>,
    public dialogRefHeader: NbDialogRef<ConfigureColumnFormComponent>,
    private _reportingService: ReportingDataService,
    private _toastrService: NbToastrService,
    private _formBuilder: FormBuilder,
    private _dialogService: NbDialogService

  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.getAllColumns();
  }

  initForm(){
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
    })
  }


  getAllColumns() {
    this._reportingService.getAllColumns().pipe(take(1)).subscribe({
      next: (response: Array<DataColumnResponse>) => {
        this.items = response;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  addItems() {
    const fieldsToAdd = [this.form.get('name').value];
    this.isLoading = true;
    this._reportingService.addColumn(fieldsToAdd).pipe(take(1)).subscribe({
      next: (data) => {
        this._toastrService.success('Column Successfully Added');
        this.getAllColumns();
        this.isLoading = false;
        this.hasChanges = true;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.isLoading = false;
      },

    })
  }

  deleteItem(item) {
    let message = `Are you sure you want to delete this column?`
    this._dialogService.open(ProceedDialogComponent, {
      context: {
        message: message,
      },
    })
    .onClose.subscribe((data) => {
      if (data) {
        this.loading = true;
        const fieldsToDelete = [item.Title];
        this._reportingService.deleteColumn(fieldsToDelete).pipe(take(1)).subscribe({
          next: (data) => {
            this._toastrService.success('Column Successfully Deleted');
            this.getAllColumns();
            this.loading = false;
            this.hasChanges = true;
          },
          error: (error) => {
            this._toastrService.danger(error);
            this.loading = false;
          },
        })
      }
    })
  }

  closeDialog(): void {
    this.dialogRefHeader.close({ hasChanges: this.hasChanges });
  }
}

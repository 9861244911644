import { AngularEditorConfig } from "@kolkov/angular-editor"

export const FIELD_PREFIX = 'field_'

// Default configuration without 'insertImage'
export const ANGULAR_EDITORCONFIG: AngularEditorConfig = {
    editable: true,
    toolbarHiddenButtons: [
      ['undo', 'redo', 'subscript', 'superscript', 'indent', 'outdent', 'fontName'],
      [
        'heading',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertVideo',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  }

  // Configuration with 'insertImage' enabled
export const INSERT_IMAGE_EDITOR_CONFIG: AngularEditorConfig = {
  editable: true,
  sanitize: false,
  toolbarHiddenButtons: [
    [
      'insertVideo',
    ],
  ]
};
